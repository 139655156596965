// Pathify
import { make } from 'vuex-pathify'
import moment from 'moment'
import _ from 'lodash'

const endpoint = 'https://g1qrqztth4.execute-api.us-east-2.amazonaws.com'
const apiMapping = '/api/v1/systemmessages'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'System Message',
  displayAsPlural: 'System Messages',
  systemMessages: {
    all: [],
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', type: '', icon: '', message: '' },
    empty: {
      id: '',
      type: 'warning',
      icon: 'mdi-information',
      message: '',
      start: '',
      end: '',
      updated: false,
    },
    backendObject: {
      id: '',
      type: 'warning',
      icon: 'mdi-information',
      message: '',
      startDateTime: '',
      endDateTime: '',
    },
    headers: {
      headerType: {
        text: 'Type',
        align: 'start',
        value: 'type',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerIcon: {
        text: 'Icon',
        align: 'start',
        value: 'icon',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerMessage: {
        text: 'Message',
        align: 'start',
        value: 'message',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerStart: {
        text: 'Start',
        align: 'start',
        value: 'start',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerEnd: {
        text: 'End',
        align: 'start',
        value: 'end',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
    },
    TypeCode: {
      warning: 'Warning',
      info: 'Info',
      success: 'Success',
      error: 'Error',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  /**
   * Mutate the busy switch to indicate if the state is busy
   * @param state   this module's state object
   * @param isBusy  data is in CRUD transit
   */
  setIsBusy: (state, isBusy) => {
    state.systemMessages.isBusy = isBusy
  },

  /**
   * Mutate the data and selected state arrays to empty
   * @param state this module's state object
   */
  setEmpty: (state) => {
    state.systemMessages.selected = []
    state.systemMessages.data = []
  },

  /**
   * Mutate the selectedItem to be the payload object
   * @param state   this module's state object
   * @param payload selected object
   */
  setSelected: (state, payload) => {
    state.systemMessages.selected = []
    state.systemMessages.selectedItem = { ...payload }
  },

  /**
   * Mutate the all and data state arrays with the retrieved data
   * @param state       this module's state object
   * @param dataFromDB  data retrieved from the database
   */
  push2All: (state, dataFromDB) => {
    state.systemMessages.all = []
    state.systemMessages.data = []

    let newItem

    for (const currentData of dataFromDB) {
      newItem = { ...state.systemMessages.empty }

      newItem.id = currentData.id
      newItem.type = currentData.type
      newItem.icon = currentData.icon
      newItem.message = currentData.message
      newItem.start = currentData.startDateTime
      newItem.end = currentData.endDateTime

      state.systemMessages.all.push(newItem)
      state.systemMessages.originalData.push(newItem)

      const messageStart = moment(newItem.start)
      const messageEnd = moment(newItem.end)

      if (messageStart !== null && messageEnd !== null) {
        if (moment().isAfter(messageStart) && moment().isBefore(messageEnd)) {
          state.systemMessages.data.push({ ...currentData })
        }
      }
    }

    state.systemMessages.selected = []

    state.systemMessages.isBusy = false
  },

  /**
   * Mutate the all array by removing the requested object
   * @param state   this module's state object
   * @param payload object to remove
   */
  remove: (state, payload) => {
    // Filter out the object with the specified ID
    const cleanData = state.systemMessages.all.filter(item => item.id !== payload.id)

    state.systemMessages.all = [...cleanData]
  },

  /**
   * Data has been successfully created
   * @param state this module's state object
   */
  successfulCreate: (state) => {},

  /**
   * Data has been successfully updated
   * @param state this module's state object
   */
  successfulUpdate: (state) => {},

  /**
   * Data has been successfully deleted
   * @param state this module's state object
   */
  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {

  },

  /**
   * Set the data state and selected state to empty array
   * @param commit  commit to mutations
   */
  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  /**
   * Set the selectedItem to the payload object
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param payload   selected object
   */
  setSelected: ({ commit, dispatch }, payload) => {
    let selectedItem

    if (payload) {
      selectedItem = { ...payload }
    } else {
      selectedItem = { ...state.systemMessages.empty }
    }

    commit('setSelected', selectedItem)
  },

  /**
   * Create a System Message
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to create
   * @returns {Promise<void>}
   */
  create: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}`

    const workPayload = { ...state.systemMessages.backendObject }

    workPayload.icon = payload.icon
    workPayload.message = payload.message
    workPayload.startDateTime = payload.start
    workPayload.endDateTime = payload.end
    workPayload.type = payload.type

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieveAll')
      })
      .then(() => {
        // console.info(`${state.displayAsSingular} Created - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulCreate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Retrieve all System Messages and store in state
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  retrieveAll: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Retrieve All ${state.displayAsPlural}`
    commit(setIsBusy, true)

    if (rootState.user.users.authState === 'signedin') {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
      const url = `${actionEndPoint}?page=0&size=999`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            if (response.status === 404) {
              state.systemMessages.all = []
              state.systemMessages.data = []
            } else {
              throw Error(statusMessage)
            }
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (!_.isEmpty(jsonResponse.data)) {
            commit('push2All', jsonResponse.data)
          } else {
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  /**
   * Update a System Message
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to update
   * @returns {Promise<void>}
   */
  update: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`
    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/${payload.id}`

    const workPayload = {
      icon: payload.icon,
      message: payload.message,
      startDateTime: payload.start,
      endDateTime: payload.end,
      type: payload.type,
    }

    const options = {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieveAll')
      })
      .then(() => {
        // console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulUpdate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Delete the selected System Message(s)
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  delete: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Delete ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/remove`

    const workPayload = []

    for (const thisItem of state.systemMessages.selected) {
      workPayload.push(thisItem.id)
    }

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          if (response.status === 404) {
            state.systemMessages.data = []
          } else {
            throw Error(statusMessage)
          }
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieveAll')
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Deleted - '${workPayload.length}' ${toastMessage}, variant: ${toastColor}`, workPayload)
        commit('successfulDelete')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Remove the requested object from systemMessages all
   * @param commit  commit to mutations
   * @param payload an object to remove from state
   */
  remove: ({ commit }, payload) => {
    commit('remove', payload)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
