// Pathify
import { make } from 'vuex-pathify'
import _ from 'lodash'

const endpoint = 'https://x94t4nasqb.execute-api.us-east-2.amazonaws.com'
const apiMapping = '/api/v1/notifications'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Notification',
  displayAsPlural: 'Notifications',
  insertCount: 0,
  lastInsert: null,
  config: {
    stageEmail: 'estimating@detecsolutionsllc.com',
    prodEmail: 'estimating@detecsolutionsllc.com',
  },
  notifications: {
    data: [],
    isBusy: false,
    empty: {
      id: null,
      email: null,
      bcc: [],
      cc: [],
      icon: null,
      subject: null,
      message: null,
      client_id: null,
      plan_id: null,
      route: null,
      read_by: null,
    },
    backendObject: {
      tenantId: 1000,
      email: null,
      bcc: [],
      cc: [],
      icon: null,
      subject: null,
      message: null,
      clientId: null,
      planId: null,
      route: null,
      readBy: null,
    },
  },
}

const mutations = {
  ...make.mutations(state),

  /**
   * Mutate the busy switch to indicate if the state is busy
   * @param state   this module's state object
   * @param isBusy  data is in CRUD transit
   */
  setIsBusy: (state, isBusy) => {
    state.notifications.isBusy = isBusy
  },

  /**
   * Mutate the data state array with the retrieved data
   * @param state       this module's state object
   * @param dataFromDB  data retrieved from the database
   */
  push2Data: (state, dataFromDB) => {
    state.notifications.data = []

    let newItem

    for (const currentData of dataFromDB) {
      newItem = { ...state.notifications.empty }

      newItem.id = currentData.id
      newItem.email = currentData.email
      newItem.bcc = currentData.bcc
      newItem.cc = currentData.cc
      newItem.icon = currentData.icon
      newItem.subject = currentData.subject
      newItem.message = currentData.message
      newItem.client_id = currentData.clientId
      newItem.plan_id = currentData.planId
      newItem.route = currentData.route
      newItem.read_by = currentData.readBy

      state.notifications.data.push(newItem)
    }

    state.notifications.isBusy = false
  },

  /**
   * Data has been successfully created
   * @param state this module's state object
   */
  successfulCreate: (state) => {},

  /**
   * Data has been successfully updated
   * @param state this module's state object
   */
  successfulUpdate: (state) => {},

  /**
   * Data has been successfully deleted
   * @param state this module's state object
   */
  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    // create at 5:15
  },

  /**
   * Create Notification
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to create
   * @returns {Promise<void>}
   */
  create: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}`

    const workPayload = { ...state.notifications.backendObject }

    workPayload.email = payload.email
    workPayload.bcc = payload.bcc
    workPayload.cc = payload.cc
    workPayload.icon = payload.icon
    workPayload.subject = payload.subject
    workPayload.message = payload.message
    workPayload.clientId = payload.client_id
    workPayload.planId = payload.plan_id
    workPayload.route = payload.route

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieveAll')
      })
      .then(() => {
        // console.info(`${state.displayAsSingular} Created - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulCreate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Create a Notification based on a Plan being created
   * @param dispatch  commit to mutations
   * @param rootState state from other modules
   * @param payload   currently selected object to create
   */
  createSubmitMessage: ({ dispatch, rootState }, payload) => {
    if (payload) {
      const newNotification = { ...state.notifications.empty }
      let env

      if (rootState.app.build_environment.toString() === 'Prod') {
        newNotification.email = state.config.prodEmail
        env = ''
      } else {
        newNotification.email = state.config.stageEmail
        env = 'Stage-'
      }

      // TODO: Show notifications to any bcc's and ask Dan who should be bcc'd on Upload/Submit Messages
      newNotification.cc = []
      newNotification.bcc = ['simlow2018@gmail.com']
      newNotification.icon = 'mdi-floor-plan'
      newNotification.subject = `${env}New Plan Submitted from ${rootState.customer.customers.selectedItem.description}`
      newNotification.message = `New Plan available, (${payload.plan_id}) ${payload.description}, submitted by ${rootState.user.users.user.attributes.email} from (${payload.client_id}) ${rootState.customer.customers.selectedItem.description}\n\nPlease schedule for Takeoff.`
      newNotification.client_id = payload.client_id
      newNotification.plan_id = payload.plan_id
      newNotification.route = '/components/plans/'

      dispatch('create', newNotification)
    } else {
      // TODO: Error?
    }
  },

  /**
   * Create a Notification based on a Plan being set to Completed Status
   * @param dispatch    commit to mutations
   * @param rootGetters getters from other modules
   * @param rootState   state from other modules
   * @param payload     currently selected object to create
   * @returns {Promise<void>}
   */
  createCompletionMessage: async ({ dispatch, rootGetters, rootState }, payload) => {
    if (payload) {
      const newNotification = { ...state.notifications.empty }
      let env

      if (rootState.app.build_environment.toString() === 'Prod') {
        newNotification.email = state.config.prodEmail
        env = ''
      } else {
        newNotification.email = state.config.stageEmail
        env = 'Stage-'
      }

      if ({}.hasOwnProperty.call(payload, 'salesperson_id')) {
        newNotification.email = await rootGetters['salesperson/getEmail'](payload.salesperson_id)
      }

      // Add Additional Salespeople email addresses to cc
      newNotification.cc = []

      for (const currentItem of JSON.parse(payload.additional_salespeople)) {
        const salespersonEmail = await rootGetters['salesperson/getEmail'](currentItem)

        if (salespersonEmail) {
          if (salespersonEmail.length > 0) {
            newNotification.cc.push(salespersonEmail)
          }
        }
      }

      newNotification.icon = 'mdi-floor-plan'
      newNotification.subject = `${env}Plan Completed: ${payload.description}`
      newNotification.message = `Plan (${payload.plan_id}) ${payload.description} is complete and ready for your review.`
      newNotification.client_id = payload.client_id
      newNotification.plan_id = payload.plan_id
      newNotification.route = '/'

      if (newNotification.email) {
        if (newNotification.email.length > 0) {
          dispatch('create', newNotification)
        }
      }
    } else {
      // TODO: Error?
    }
  },

  /**
   * Retrieve Notifications by email
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  retrieveAll: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Retrieve All ${state.displayAsPlural}`
    commit(setIsBusy, true)

    if (rootState.user.users.authState === 'signedin') {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}/useremail`
      const url = `${actionEndPoint}/${rootState.user.users.user.attributes.email}?page=0&size=999&sort=createdAt,desc`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            if (response.status === 404) {
              state.notifications.data = []
            } else {
              throw Error(statusMessage)
            }
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (!_.isEmpty(jsonResponse.data)) {
            commit('push2Data', jsonResponse.data)
          } else {
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  /**
   * Update a Notification
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to update
   * @returns {Promise<void>}
   */
  update: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/${payload.id}`

    let readBy = []

    if ({}.hasOwnProperty.call(payload, 'read_by')) {
      if (payload.read_by) {
        readBy = [...payload.read_by]
      }
    }

    if (!readBy.includes(rootState.user.users.user.attributes.email)) {
      readBy.push(rootState.user.users.user.attributes.email)
    }

    const workPayload = {
      email: payload.email,
      subject: payload.subject,
      message: payload.message,
      clientId: payload.client_id,
      planId: payload.plan_id,
      readBy: readBy,
    }

    const options = {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieveAll')
      })
      .then(() => {
        // console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulUpdate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Delete the Notification passed in via payload object
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   notification to delete
   * @returns {Promise<void>}
   */
  delete: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Delete ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/remove`

    const workPayload = []

    workPayload.push(payload.id)

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          if (response.status === 404) {
            state.notifications.data = []
          } else {
            throw Error(statusMessage)
          }
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieveAll')
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Deleted - '${workPayload.length}' ${toastMessage}, variant: ${toastColor}`, workPayload)
        commit('successfulDelete')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
