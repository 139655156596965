<template>
  <div>
    <v-data-table
      ref="maintenanceTableSystemMessages"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.all"
      class="elevation-2"
      sort-by="end"
      sort-desc
      show-select
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.all.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <!-- Type -->
      <template #item.type="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <select-enum-string
            ref="typeCd"
            v-model="editedItem.type"
            :label="maintenanceState.headers.headerType.text"
            :enum="maintenanceState.TypeCode"
            :required="true"
            @change="field_onChange"
          />
        </div>
        <span v-else>{{ item.type }}</span>
      </template>

      <!-- Icon -->
      <template #item.icon="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <select-icon
            ref="editedIcon"
            v-model="editedItem.icon"
            label="Icon"
            :required="true"
            :clearable="false"
            @change="field_onChange"
          />
        </div>

        <span v-else>{{ item.icon }}</span>
      </template>

      <!-- Message -->
      <template #item.message="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <message
            ref="editedMessage"
            v-model="editedItem"
            label="Message"
            :originalData="maintenanceState.originalData"
            :required="true"
            :clearable="false"
            :rules="[appRules.required]"
            @change="disableEdit = getDisableEdit()"
          />
        </div>

        <span v-else>
          <v-alert
            :icon="item.icon"
            :type="item.type"
            class="mt-1 mb-1"
          >
            {{ item.message }}
          </v-alert>
        </span>
      </template>

      <!-- Start -->
      <template #item.start="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <date-field
            ref="startDate"
            v-model="editedItem.start"
            label=""
            :clearable="false"
            :show-read-only-date="item.id !== editedItem.id"
            :nudge-right="-300"
            @change="field_onChange"
          />
          <time-field
            ref="startTime"
            v-model="editedItem.start"
            label=""
            :clearable="false"
            :show-read-only-date="item.id !== editedItem.id"
            :nudge-right="-300"
            @change="field_onChange"
          />
        </div>

        <span v-else>
          {{ formatDateForTimezone(item.start) }}<br />
          {{ formatTimeForTimezone(item.start) }}
        </span>
      </template>

      <!-- End -->
      <template #item.end="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <date-field
            ref="endDate"
            v-model="editedItem.end"
            label=""
            :clearable="false"
            :show-read-only-date="false"
            :nudge-right="-300"
            @change="field_onChange"
          />
          <time-field
            ref="endTime"
            v-model="editedItem.end"
            label=""
            :clearable="false"
            :show-read-only-date="false"
            :nudge-right="-300"
            @change="field_onChange"
          />
        </div>

        <span v-else>
          {{ formatDateForTimezone(item.end) }}<br />
          {{ formatTimeForTimezone(item.end) }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.id === editedItem.id">
          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                color="accent"
                class="mr-2"
                :disabled="disableEdit"
                v-bind="attrs"
                v-on="on"
                @click="saveEdit(item)"
              >
                mdi-content-save
              </v-icon>
            </template>
            <span>Save</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                small
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="cancelEdit(item)"
              >
                mdi-window-close
              </v-icon>
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </div>

        <div v-else>
          <v-icon
            small
            color="primary"
            class="mr-2"
            @click="menuEdit_onClick(item)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            small
            color="primary"
            @click="openDeleteDialog(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :use-customer-detail="false"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import SelectIcon from '@/components/maintenance/controls/SelectIcon'
  import DateField from '@/components/maintenance/controls/DateField.vue'
  import TimeField from '@/components/maintenance/controls/TimeField.vue'
  import SelectEnumString from '@/components/maintenance/controls/SelectEnumString.vue'
  import Message from '@/components/maintenance/controls/Message.vue'

  export default {
    name: 'SystemMessagesTable',

    components: {
      Message,
      SelectEnumString,
      DateField,
      TimeField,
      DeleteDialog,
      ContextMenu,
      SelectIcon,
    },

    data () {
      return {
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'formatDateForTimezone',
        'formatTimeForTimezone',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      displayAsSingular: get('systemMessage/displayAsSingular'),
      displayAsPlural: get('systemMessage/displayAsPlural'),
      maintenanceState: get('systemMessage/systemMessages'),
      disableNew () {
        return false
      },
    },

    watch: {
      editedIndex: {
        handler (val, oldVal) {
          this.setupHeaders()
        },
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setupHeaders () {
        this.headers = []

        if (this.editedIndex > -1) {
          // edit mode
          this.maintenanceState.headers.headerType.width = '15%'
          this.maintenanceState.headers.headerIcon.width = '20%'
          this.maintenanceState.headers.headerMessage.width = '34%'
          this.maintenanceState.headers.headerStart.width = '15%'
          this.maintenanceState.headers.headerEnd.width = '15%'

          this.headers.push(this.maintenanceState.headers.headerType)
          this.headers.push(this.maintenanceState.headers.headerIcon)
        } else {
          // view mode
          this.maintenanceState.headers.headerMessage.width = '75%'
          this.maintenanceState.headers.headerStart.width = '12%'
          this.maintenanceState.headers.headerEnd.width = '12%'
        }

        this.headers.push(this.maintenanceState.headers.headerMessage)
        this.headers.push(this.maintenanceState.headers.headerStart)
        this.headers.push(this.maintenanceState.headers.headerEnd)
        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        this.setupHeaders()
      },

      forceRerender () {
        this.renderKey += 1
      },

      getDisableEdit () {
        let disabled = (this.editedItem.updated !== null ? !this.editedItem.updated : true)

        if (!disabled && this.$refs.typeCd) {
          disabled = this.$refs.typeCd.$refs.selectenum.hasError
        }

        if (!disabled && this.$refs.editedIcon) {
          disabled = this.$refs.editedIcon.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.editedMessage) {
          disabled = this.$refs.editedMessage.$refs.textField.hasError
        }

        return disabled
      },

      field_onChange () {
        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      openDeleteDialog (dataItem) {
        dataItem.description = `${dataItem.type} ${dataItem.icon}`
        this.maintenanceState.selected.push(dataItem)
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('systemMessage/delete')
      },

      cancelDelete () {
        this.maintenanceState.selected = []
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        selectedRow.item.description = `${selectedRow.item.type} ${selectedRow.item.icon}`
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.disableEdit = true

        // Store datetime as local and change back to zulu when saving
        const addObj = Object.assign({}, this.maintenanceState.empty)
        const dateNow = new Date()

        addObj.id = 0
        addObj.start = dateNow.toLocaleString()
        addObj.end = dateNow.toLocaleString()

        this.maintenanceState.all.unshift(addObj)

        this.menuEdit_onClick(addObj)
      },

      menuEdit_onClick (dataItem) {
        this.disableEdit = true
        this.editedIndex = this.maintenanceState.all.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        if (this.editedItem.id === 0 && this.editedItem.version === 0) {
          // If id & version = 0 the user is cancelling an added item, which must be removed
          this.$store.dispatch('systemMessage/remove', this.editedItem)
        }

        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        if (this.editedItem.id === 0) {
          this.$store.dispatch('systemMessage/create', { ...this.editedItem })
        } else {
          this.$store.dispatch('systemMessage/update', { ...this.editedItem })
        }

        this.$nextTick(() => {
          this.cancelEdit()
        })
      },
    },
  }
</script>

<style scoped>

</style>
