// Pathify
import { make } from 'vuex-pathify'
import _ from 'lodash'

const endpoint = 'https://sob81w7og6.execute-api.us-east-2.amazonaws.com'
const apiMapping = '/api/v1/usersettings'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'User Setting',
  displayAsPlural: 'User Settings',
  userSettings: {
    current: null,
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', value: '', description: '' },
    empty: {
      id: '',
      email: '',
      default_customer: null,
      customers: [],
      primary_color: '#9A2A2A',
      secondary_color: '#212121',
      accent_color: '#0537ad',
      image: '0',
      gradient: '0',
      dark: false,
      show_image: true,
      updated: false,
    },
    backendObject: {
      userEmail: '',
      defaultCustomer: null,
      customers: [],
      primaryColor: '#9A2A2A',
      secondaryColor: '#212121',
      accentColor: '#0537ad',
      image: '0',
      gradient: '0',
      dark: false,
      showImage: true,
    },
    headers: {
      headerUserEmail: {
        text: 'User Email Address',
        align: 'start',
        value: 'email',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDefaultCustomer: {
        text: 'Default Customer',
        align: 'start',
        value: 'default_customer',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerCustomers: {
        text: 'Customers',
        align: 'start',
        value: 'customers',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerPrimaryColor: {
        text: 'Primary Color',
        align: 'start',
        value: 'primary_color',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerSecondaryColor: {
        text: 'Secondary Color',
        align: 'start',
        value: 'secondary_color',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerAccentColor: {
        text: 'Accent Color',
        align: 'start',
        value: 'accent_color',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerImage: {
        text: 'Selected Image',
        align: 'center',
        value: 'image',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerGradient: {
        text: 'Selected Gradient',
        align: 'center',
        value: 'gradient',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDarkMode: {
        text: 'Dark Mode',
        align: 'start',
        value: 'dark',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1 align-center',
      },
      headerShowImage: {
        text: 'Show Image',
        align: 'start',
        value: 'show_image',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1 align-center',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  /**
   * Mutate the busy switch to indicate if the state is busy
   * @param state   this module's state object
   * @param isBusy  data is in CRUD transit
   */
  setIsBusy: (state, isBusy) => {
    state.userSettings.isBusy = isBusy
  },

  /**
   * Mutate the data and selected state arrays to empty
   * @param state this state object
   */
  setEmpty: (state) => {
    state.userSettings.selected = []
    state.userSettings.data = []
  },

  /**
   * Mutate the selectedItem to be the payload object
   * @param state   this module's state object
   * @param payload selected object
   */
  setSelected: (state, payload) => {
    state.userSettings.selected = []
    state.userSettings.selectedItem = { ...payload }
  },

  /**
   * Mutate the data state array with the retrieved data
   * @param state       this module's state object
   * @param dataFromDB  data retrieved from the database
   */
  push2Data: (state, dataFromDB) => {
    state.userSettings.data = []

    let newItem

    for (const currentData of dataFromDB) {
      newItem = { ...state.userSettings.empty }

      newItem.id = currentData.id
      newItem.email = currentData.userEmail
      newItem.default_customer = currentData.defaultCustomer
      newItem.customers = currentData.customers
      newItem.primary_color = currentData.primaryColor
      newItem.secondary_color = currentData.secondaryColor
      newItem.accent_color = currentData.accentColor
      newItem.image = currentData.image
      newItem.gradient = currentData.gradient
      newItem.dark = currentData.dark
      newItem.show_image = currentData.showImage

      state.userSettings.data.push(newItem)
      state.userSettings.originalData.push(newItem)
    }

    state.userSettings.selected = []

    state.userSettings.isBusy = false
  },

  /**
   * Mutate the current state object with the retrieved data
   * @param state       this module's state object
   * @param dataFromDB  data retrieved from the database
   */
  push2Current: (state, dataFromDB) => {
    state.userSettings.current = { ...state.userSettings.empty }

    state.userSettings.current.id = dataFromDB.id
    state.userSettings.current.email = dataFromDB.userEmail
    state.userSettings.current.default_customer = dataFromDB.defaultCustomer
    state.userSettings.current.customers = dataFromDB.customers
    state.userSettings.current.primary_color = dataFromDB.primaryColor
    state.userSettings.current.secondary_color = dataFromDB.secondaryColor
    state.userSettings.current.accent_color = dataFromDB.accentColor
    state.userSettings.current.image = dataFromDB.image
    state.userSettings.current.gradient = dataFromDB.gradient
    state.userSettings.current.dark = dataFromDB.dark
    state.userSettings.current.show_image = dataFromDB.showImage

    state.userSettings.isBusy = false
  },

  /**
   * Data has been successfully created
   * @param state this module's state object
   */
  successfulCreate: (state) => {},

  /**
   * Data has been successfully updated
   * @param state this module's state object
   */
  successfulUpdate: (state) => {},

  /**
   * Data has been successfully deleted
   * @param state this module's state object
   */
  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {

  },

  /**
   * Set the data state and selected state to empty array
   * @param commit  commit to mutations
   */
  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  /**
   * Set the selectedItem to the payload object
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param payload   currently selected object
   */
  setSelected: ({ commit, dispatch }, payload) => {
    let selectedItem

    if (payload) {
      selectedItem = { ...payload }
    } else {
      selectedItem = { ...state.userSettings.empty }
    }

    commit('setSelected', selectedItem)
  },

  /**
   * Create User Settings
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to create
   * @returns {Promise<void>}
   */
  create: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Create ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}`

    const workPayload = { ...state.userSettings.backendObject }

    workPayload.userEmail = payload.email
    workPayload.defaultCustomer = payload.default_customer
    workPayload.customers = payload.customers
    workPayload.primaryColor = payload.primary_color
    workPayload.secondaryColor = payload.secondary_color
    workPayload.accentColor = payload.accent_color
    workPayload.image = payload.image
    workPayload.gradient = payload.gradient
    workPayload.dark = payload.dark
    workPayload.showImage = payload.show_image

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieve')
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Created - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulCreate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Retrieve User Settings for all users
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  retrieve: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Retrieve ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}?page=0&size=999`

    const options = {
      method: 'get',
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          if (response.status === 404) {
            state.userSettings.data = []
          } else {
            throw Error(statusMessage)
          }
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (!_.isEmpty(jsonResponse.data)) {
          commit('push2Data', jsonResponse.data)
        } else {
          commit(setIsBusy, false)
        }
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Retrieve User Settings for a specific email address
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param userEmail email address to retrieve
   * @returns {Promise<void>}
   */
  retrieveByEmail: async ({ commit, dispatch, rootState }, userEmail) => {
    const thisAction = `Retrieve ${state.displayAsPlural} By Email`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}/useremail`
    const url = `${actionEndPoint}/${userEmail}`

    const options = {
      method: 'get',
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        // TODO: What will I get back for an error?
        if (!_.isEmpty(jsonResponse.data)) {
          commit('push2Current', jsonResponse.data)
        } else {
          // toastColor = 'danger'
          // toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.message }, { root: true })
          commit(setIsBusy, false)
        }
      })
      .then(() => {
        dispatch('user/getUserSettings', null, { root: true })
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Update a User Settings
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to update
   * @returns {Promise<void>}
   */
  update: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Update ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/${payload.id}`

    const workPayload = { ...state.userSettings.backendObject }

    workPayload.userEmail = payload.email
    workPayload.defaultCustomer = payload.default_customer
    workPayload.customers = payload.customers
    workPayload.primaryColor = payload.primary_color
    workPayload.secondaryColor = payload.secondary_color
    workPayload.accentColor = payload.accent_color
    workPayload.image = payload.image
    workPayload.gradient = payload.gradient
    workPayload.dark = payload.dark
    workPayload.showImage = payload.show_image

    const options = {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieve')
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulUpdate')

        // If the User Settings that are getting updated are the current user, reset current user settings by retrieving them again
        if (payload.email === rootState.user.users.user.attributes.email) {
          dispatch('userSettings/retrieveByEmail', payload.email, { root: true })
        }
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Delete the selected User Settings
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  delete: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Delete ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/remove`

    const workPayload = []

    for (const thisItem of state.userSettings.selected) {
      workPayload.push(thisItem.id)
    }

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          if (response.status === 404) {
            state.userSettings.data = []
          } else {
            throw Error(statusMessage)
          }
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieve')
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Deleted - '${workPayload.length}' ${toastMessage}, variant: ${toastColor}`, workPayload)
        commit('successfulDelete')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
