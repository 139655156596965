<template>
  <v-main>
    <system-error />

    <v-container fluid>
      <v-snackbar
        v-model="toast2Display.display"
        :timeout="toast2Display.timeout"
        :color="toast2Display.variant"
        v-bind="{
          [toast2Display.parsedDirection[0]]: true,
          [toast2Display.parsedDirection[1]]: true
        }"
        :multi-line="true"
        class="non-printable"
      >
        <div>
          <div class="pb-2">
            <h3>{{ toast2Display.title }}</h3>
          </div>
          <v-divider />
          <div
            v-for="(thisMessage, index) in toast2Display.messages"
            :key="index"
            class="pt-1"
          >
            {{ thisMessage }}
          </div>
        </div>

        <template v-slot:action="{ }">
          <v-icon
            aria-label="Close"
            @click="toast2Display.display = false"
          >
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>

      <router-view :key="$route.path" />
    </v-container>
  </v-main>
</template>

<script>
  import SystemError from '@/layouts/default/SystemError'
  import { get } from 'vuex-pathify'
  import { Auth } from 'aws-amplify'

  export default {
    name: 'DefaultView',

    components: {
      SystemError,
    },

    data: () => ({
      initializedToast: {
        display: false,
        title: '',
        messages: [],
        text: '',
        variant: 'success',
        parsedDirection: ['top', 'center'],
        timeout: 4000,
      },
      toast2Display: {
        display: false,
        messages: [],
        variant: 'success',
        parsedDirection: ['top', 'center'],
        timeout: 4000,
      },
    }),

    computed: {
      users: get('user/users'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      categories: get('category/categories'),
    },

    created () {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'user/updateTheme':
            this.initializeTheme()
            break
          case 'user/setUserSettings':
            this.initializeTheme()
            this.verifyDefaultCustomer(this.users.default.client_id)
            /*
            if (mutation.payload === null) {
              // location.reload()
              // this.$router.go(this.$router.currentRoute)
              this.$router.go(0)
            }
             */
            this.$store.dispatch('customer/retrieveAll')
            break
          case 'user/showToast':
            this.displayToast(mutation.payload)
            break
          case 'division/push2All':
            this.$store.dispatch('division/retrieve', this.plans.selectedItem)
            break
          case 'assembly/push2All':
            this.$store.dispatch('assembly/retrieve', this.divisions.selectedItem)
            break
          case 'part/push2All':
            this.$store.dispatch('part/retrieve', this.categories.selectedItem)
            break
          default:
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      async signOut () {
        try {
          await Auth.signOut()
          await this.$store.dispatch('user/setAuth', { authState: 'signedout', authData: undefined })
        } catch (error) {
          console.error('error signing out: ', error)
        }
      },

      verifyDefaultCustomer (defaultCustomer) {
        if (defaultCustomer) {
          if (defaultCustomer.toString().trim() === '0000') {
            this.signOut()
          }
        }
      },

      initializeTheme () {
        if (this.users.settings) {
          this.$vuetify.theme.dark = this.users.settings.dark
        }

        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = this.users.selectedColors.primary
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].secondary = this.users.selectedColors.secondary
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].accent = this.users.selectedColors.accent
      },

      displayToast (payload) {
        this.toast2Display = { ...this.initializedToast }

        this.toast2Display.title = payload.title
        this.toast2Display.messages = payload.messages

        if (payload.variant) {
          this.toast2Display.variant = payload.variant
        }

        if (payload.target) {
          this.toast2Display.parsedDirection = payload.target.split(' ')
        }

        if (payload.timeout) {
          this.toast2Display.timeout = payload.timeout
        }

        this.toast2Display.display = true
      },
    },
  }
</script>

<style>
.navToLink {
  display: inline;
}
.navToLink:hover {
  color: blue;
  cursor: pointer;
  font-weight: bold;
}
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
