// Utilities
import { make } from 'vuex-pathify'

// Globals
import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  IN_BROWSER,
  QQ_ACCENT_COLOR,
  QQ_PRIMARY_COLOR,
  QQ_SECONDARY_COLOR,
} from '@/util/globals'
import { readCookie } from '@/util/helpers'
import router from '@/router'
import moment from 'moment'
import _ from 'lodash'
import { API, Auth } from 'aws-amplify'

const state = {
  nextToken: null,
  users: {
    sessionTime: null,
    authState: undefined,
    update_program: '',
    isAdmin: false,
    isUserAdmin: false,
    isEstimator: false,
    isGuest: false,
    isCustomer: false,
    allowStage: false,
    user: undefined,
    settings: null,
    default: {
      clients: [],
      client_id: null,
    },
    selectedColors: {
      primary: null,
      secondary: null,
      accent: null,
    },
  },
  dark: false,
  drawer: {
    image: 0,
    gradient: 0,
    mini: false,
  },
  gradients: [
    'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
    'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
    'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
  ],
  /*
  'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
  'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
   */
  images: [
    require('@/assets/BLUE-PRINT.jpg'),
    require('@/assets/blueprints-blue.png'),
    require('@/assets/blueprint-old-house.jpg'),
    require('@/assets/DETEC-VERTICAL.jpg'),
  ],
  notifications: [],
  rtl: false,
}

const mutations = {
  ...make.mutations(state),

  successfulLogin: () => {},

  /**
   * Mutate the users.settings state object with the payload
   * @param state   this module's state object
   * @param payload User Settings object
   */
  setUserSettings: (state, payload) => {
    state.users.settings = payload
    state.users.default.clients = [...payload.customers]

    if (state.users.default.client_id == null) {
      state.users.default.client_id = payload.default_customer
    }

    state.dark = payload.dark
    state.drawer.image = payload.image
    state.drawer.gradient = payload.gradient

    // Set Primary Color
    let qqPrimaryColor = payload.primary_color
    if (qqPrimaryColor === null) {
      qqPrimaryColor = readCookie(QQ_PRIMARY_COLOR)
      if (!qqPrimaryColor) {
        qqPrimaryColor = DEFAULT_PRIMARY_COLOR
      }
    }
    state.users.selectedColors.primary = qqPrimaryColor

    // Set Secondary Color
    let qqSecondaryColor = payload.secondary_color
    if (qqSecondaryColor === null) {
      qqSecondaryColor = readCookie(QQ_SECONDARY_COLOR)
      if (!qqSecondaryColor) {
        qqSecondaryColor = DEFAULT_SECONDARY_COLOR
      }
    }
    state.users.selectedColors.secondary = qqSecondaryColor

    // Set Accent Color
    let qqAccentColor = payload.accent_color
    if (qqAccentColor === null) {
      qqAccentColor = readCookie(QQ_ACCENT_COLOR)
      if (!qqAccentColor) {
        qqAccentColor = DEFAULT_ACCENT_COLOR
      }
    }
    state.users.selectedColors.accent = qqAccentColor
  },

  /**
   * Mutate the users.selectedColor state object with the Selected Colors payload
   * @param state   this module's state object
   * @param payload Selected Colors object
   */
  updateTheme: (state, payload) => {
    state.users.selectedColors.primary = payload.primary
    state.users.selectedColors.secondary = payload.secondary
    state.users.selectedColors.accent = payload.accent
  },

  /**
   * Mutate the users.settings state object with the show_image boolean payload
   * @param state   this module's state object
   * @param payload show_image boolean
   */
  setShowImage: (state, payload) => {
    state.users.settings.show_image = payload
  },

  /**
   * Mutate the users.settings state object with the image index payload
   * @param state   this module's state object
   * @param payload image index
   */
  setImage: (state, payload) => {
    state.users.settings.image = payload
  },

  /**
   * Mutate the users.settings state object with the gradient index payload
   * @param state   this module's state object
   * @param payload gradient index
   */
  setGradient: (state, payload) => {
    state.users.settings.gradient = payload
  },

  /**
   * Mutate the users.settings state object with the dark mode boolean payload
   * @param state   this module's state object
   * @param payload dark mode boolean
   */
  setDarkMode: (state, payload) => {
    state.users.settings.dark = payload
  },

  showToast: (state, payload) => {},

  invalidateSession: () => {},
}

const actions = {
  fetch: ({ commit }) => {
    const local = localStorage.getItem('vuetify@user') || '{}'
    const user = JSON.parse(local)

    for (const key in user) {
      commit(key, user[key])
    }

    if (user.dark === undefined) {
      commit('dark', window.matchMedia('(prefers-color-scheme: dark)'))
    }
  },

  update: ({ state }) => {
    if (!IN_BROWSER) return

    localStorage.setItem('vuetify@user', JSON.stringify(state))
  },

  showToast: ({ commit }, payload) => {
    commit('showToast', payload)
  },

  invalidateSession: async ({ commit, dispatch }) => {
    state.users.sessionTime = null
    state.users.authState = 'signedout'
    state.users.isAdmin = false
    state.users.isUserAdmin = false
    state.users.isEstimator = false
    state.users.isGuest = false
    state.users.isCustomer = false
    state.users.allowStage = false
    state.users.user = undefined

    dispatch('customer/setEmpty', null, { root: true })

    localStorage.clear()

    commit('invalidateSession')
  },

  /**
   * Set the User Settings to the payload object
   * @param commit    commit to mutations
   * @param payload   User Settings object
   */
  setUserSettings: ({ commit }, payload) => {
    commit('setUserSettings', payload)
  },

  /**
   * Get User Settings from the state object .current
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  getUserSettings: async ({ dispatch, rootState }) => {
    const settings = { ...rootState.userSettings.userSettings.current }

    if (settings) {
      dispatch('setUserSettings', settings)
    }
  },

  successfulLogin: async ({ commit }) => {
    commit('successfulLogin')
  },

  setAuth: async ({ commit, dispatch, rootGetters, rootState }, payload) => {
    const thisAction = 'Set Auth'
    let groups = []

    state.users.user = undefined

    if (payload.authData) {
      state.users.user = { attributes: {} }
      state.users.user.attributes = payload.authData.attributes
      state.users.user.attributes.groups = payload.authData.signInUserSession.accessToken.payload['cognito:groups']
      state.users.user.attributes.event_id = payload.authData.signInUserSession.accessToken.payload.event_id
      state.users.user.attributes.jti = payload.authData.signInUserSession.accessToken.payload.jti
      state.users.user.attributes.origin_jti = payload.authData.signInUserSession.accessToken.payload.origin_jti
      // TODO: Save event_id in User Settings for session comparison in router.beforeEach
      // console.log(`TLH ${thisAction}`, state.users.user.attributes)
    }

    let sessionTime

    switch (payload.authState) {
      case 'signedin':
        groups = state.users.user.attributes.groups

        sessionTime = localStorage.getItem('vuetify@userSessionTime') || moment()
        localStorage.setItem('vuetify@userSessionTime', sessionTime)

        state.users.sessionTime = sessionTime
        state.users.isAdmin = false
        state.users.isUserAdmin = false
        state.users.isEstimator = false
        state.users.isGuest = false
        state.users.isCustomer = false
        state.users.allowStage = false
        state.users.default.clients = []
        state.users.default.client_id = null

        if (groups) {
          for (let i = 0; i < groups.length; i++) {
            switch (groups[i]) {
              case 'Administrators':
                state.users.isAdmin = true
                break
              case 'UserAdmin':
                state.users.isUserAdmin = true
                break
              case 'Estimators':
                state.users.isEstimator = true
                break
              case 'ServiceGuest':
                state.users.isGuest = true
                break
              case 'Customer':
                state.users.isCustomer = true
                break
              case 'AllowStage':
                state.users.allowStage = true
                break
              default:
                console.info('Unhandled group: ', groups[i])
                break
            }
          }

          if (rootState.app.build_environment === 'Stage') {
            if (!state.users.allowStage) {
              dispatch('user/showToast',
                {
                  title: 'Unauthorized',
                  messages: ['This user does not have permissions to this environment.'],
                  variant: 'error',
                  timeout: 6000,
                },
                { root: true },
              )

              dispatch('invalidateSession')

              return
            }
          }
        }

        state.users.authState = payload.authState

        if (router.currentRoute.path !== '/') await router.push('/')

        dispatch('successfulLogin')
        dispatch('userSettings/retrieveByEmail', state.users.user.attributes.email, { root: true })

        break
      case 'signedout':
        dispatch('invalidateSession')

        break
    }
  },

  setPageName: ({ commit, dispatch }, pageName) => {
    state.users.update_program = pageName
    // TODO: moment format deprecated [ tried .format('YYYYMMDDHHmmss'), .setDate(.getDate()), .toISOString() ]
    /*
    const threshold = moment(state.users.sessionTime).add(7, 'days')
    const dateNow = moment()

    if (threshold && dateNow && moment(dateNow).isAfter(threshold)) {
      dispatch('invalidateSession')
    }
     */
  },

  setStateAsUpdated: ({ commit, rootState }, payload) => {
    payload.updated = true
    payload.update_program = state.users.update_program
    payload.update_user_id = state.users.user.attributes.email

    if ({}.hasOwnProperty.call(payload, 'client_id')) {
      if (payload.client_id) {
        if (payload.client_id.length <= 0) {
          payload.client_id = rootState.customer.customers.selectedItem.client_id
        }
      } else {
        payload.client_id = rootState.customer.customers.selectedItem.client_id
      }
    }
  },

  /**
   * Set the User Settings Primary Color and immediately update the theme
   * @param commit  commit to mutations
   * @param payload currently selected color
   * @returns {Promise<void>}
   */
  setPrimaryColor: async ({ commit }, payload) => {
    const newTheme = {
      primary: payload,
      secondary: state.users.selectedColors.secondary,
      accent: state.users.selectedColors.accent,
    }

    commit('updateTheme', newTheme)
  },

  /**
   * Set the User Settings Secondary Color and immediately update the theme
   * @param commit  commit to mutations
   * @param payload currently selected color
   * @returns {Promise<void>}
   */
  setSecondaryColor: async ({ commit }, payload) => {
    const newTheme = {
      primary: state.users.selectedColors.primary,
      secondary: payload,
      accent: state.users.selectedColors.accent,
    }

    commit('updateTheme', newTheme)
  },

  /**
   * Set the User Settings Accent Color and immediately update the theme
   * @param commit  commit to mutations
   * @param payload currently selected color
   * @returns {Promise<void>}
   */
  setAccentColor: async ({ commit }, payload) => {
    const newTheme = {
      primary: state.users.selectedColors.primary,
      secondary: state.users.selectedColors.secondary,
      accent: payload,
    }

    commit('updateTheme', newTheme)
  },

  /**
   * Set the User Settings show_image boolean
   * @param commit  commit to mutations
   * @param payload show_image boolean
   * @returns {Promise<void>}
   */
  setShowImage: async ({ commit }, payload) => {
    commit('setShowImage', payload)
  },

  /**
   * Set the User Settings image index
   * @param commit  commit to mutations
   * @param payload image index
   * @returns {Promise<void>}
   */
  setImage: async ({ commit }, payload) => {
    commit('setImage', payload)
  },

  /**
   * Set the User Settings gradient index
   * @param commit  commit to mutations
   * @param payload gradient index
   * @returns {Promise<void>}
   */
  setGradient: async ({ commit }, payload) => {
    commit('setGradient', payload)
  },

  /**
   * Set the User Settings dark mode boolean
   * @param commit  commit to mutations
   * @param payload dark mode boolean
   * @returns {Promise<void>}
   */
  setDarkMode: async ({ commit }, payload) => {
    commit('setDarkMode', payload)
  },
}

const getters = {
  dark: (state, getters) => {
    return (
      state.dark ||
      getters.gradient.indexOf('255, 255, 255') === -1
    )
  },

  gradient: state => {
    let currentValue = state.gradients[0]

    if (state.users.settings) {
      currentValue = state.gradients[state.users.settings.gradient]
    }

    return currentValue
  },

  image: state => {
    let currentValue = state.images[0]

    if (state.users.settings) {
      currentValue = state.images[state.users.settings.image]
    }

    return currentValue
  },

  getAllowedCustomers: () => (payload) => {
    let customerData = []

    // TODO: We may want to require All and empty shows none to keep clients from seeing other clients
    if (_.isEmpty(state.users.default.clients)) {
      if (state.users.default !== null) {
        if ({}.hasOwnProperty.call(state.users.default, 'client_id')) {
          if (state.users.default.client_id !== null) {
            if (state.users.default.client_id.trim().length > 0) {
              customerData = payload
            }
          }
        }
      }
    } else {
      for (const currentData of payload) {
        if (state.users.default.clients.includes(currentData.client_id.toString())) {
          // push client to data
          customerData.push({ ...currentData })
        }
      }
    }

    return customerData
  },

  /***
   * If the user is an Estimator, return true for valid Estimator statuses, otherwise return true for all other roles
   * @param state   user state
   * @param payload plan data
   * @returns {function(*): boolean}
   */
  hasValidEstimatorStatusCode: (state) => (payload) => {
    let allowed = true

    if (payload) {
      if (state.users.isEstimator) {
        if ({}.hasOwnProperty.call(payload, 'status_cd')) {
          switch (payload.status_cd) {
            case 'progress':
            case 'training':
            case 'review':
              allowed = true
              break
            default:
              allowed = false
              break
          }
        }
      }
    }

    return allowed
  },

  /**
   * Get allowed statuses for a Takeoff Report based on the user's authority
   * @param state   user state
   * @param payload plan data
   * @returns {function(*): boolean}
   */
  hasValidTakeoffStatusCode: (state) => (payload) => {
    let allowed = true

    if (payload) {
      if (state.users.isGuest) {
        if ({}.hasOwnProperty.call(payload, 'status_cd')) {
          switch (payload.status_cd) {
            case 'complete':
            case 'invoiced':
              allowed = true
              break
            default:
              allowed = false
              break
          }
        }
      } else {
        allowed = true
      }
    }

    return allowed
  },

  /***
   * Get Estimator's Plans from payload
   * @deprecated No longer used as we will now key Estimator's off of their email
   * @param payload Array of plan data
   * @param defaultEstimator Default Estimator ID to filter plan data on
   * @returns {function(*, *): *[]} Array of Estimators Plans
   */
  getEstimatorsData: () => (payload, defaultEstimator) => {
    let estimatorsData = []

    if (defaultEstimator) {
      for (const currentData of payload) {
        if (currentData.estimator_id) {
          if (currentData.estimator_id === defaultEstimator) {
            // push estimator's data
            estimatorsData.push({ ...currentData })
          }
        } else {
          // push unassigned or null estimator_id as well
          estimatorsData.push({ ...currentData })
        }
      }
    } else {
      estimatorsData = payload
    }

    return estimatorsData
  },

  getUserEmail: (state) => () => {
    let userEmail = null

    if (typeof state.users.user !== 'undefined') {
      if (state.users.user.attributes !== null) {
        if ({}.hasOwnProperty.call(state.users.user.attributes, 'email')) {
          userEmail = state.users.user.attributes.email.toString().trim()
        }
      }
    }

    return userEmail
  },

  // TODO: Can't get AdminQueries added to already existing auth setup without removing and starting over: https://github.com/aws-amplify/amplify-cli/issues/6015
  /***
   * Get a list of users in the specified group
   * @param dispatch  dispatch other actions
   * @param state     user state
   * @returns {function(groupName: String, limit: Number): Promise<*>}
   */
  listUsersInGroup: (dispatch, state) => async (groupName, limit) => {
    let response
    const thisAction = 'List Users in Group'

    try {
      const authorization = (await Auth.currentSession()).getAccessToken().getJwtToken()
      const apiName = 'AdminQueries'
      const path = '/listUsersInGroup'
      const myInit = {
        queryStringParameters: {
          groupname: groupName,
          limit: limit,
          token: state.nextToken,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${authorization}`,
        },
      }

      response = await API.get(apiName, path, myInit)
    } catch (error) {
      console.error(`${thisAction} failed`, error)
      // dispatch('error/setError', { name: thisAction, details: error }, { root: true })
    }

    return response
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
