// Pathify
import { make } from 'vuex-pathify'
import { dateInRange, getChunk, importChunk } from '@/util/helpers'
import store from '@/store'
import { Storage } from 'aws-amplify'

const _ = require('lodash')

const endpoint = 'https://pjcc1m20o5.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Plan',
  displayAsPlural: 'Plans',
  plans: {
    all: [],
    archived: [],
    dataFrom: [],
    data: [],
    originalData: [],
    toImport: [],
    importData: [],
    estimatorsSummary: [],
    customersSummary: [],
    isBusyFrom: false,
    isBusy: false,
    isFirst: false,
    isLast: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    selectedFrom: { id: '', description: '' },
    setLast: false,
    filter_guest_status_codes: ['submitted', 'progress', 'review', 'complete', 'invoiced', 'hold', 'cancelled'],
    filter_customer_status_codes: ['submitted', 'progress', 'review', 'complete', 'invoiced', 'hold', 'cancelled', 'template'],
    filter_customer_all_status_codes: ['submitted', 'progress'],
    filter_report_status_codes: [],
    filter_data_status_codes: ['submitted', 'progress', 'training', 'review', 'complete', 'invoiced', 'hold', 'cancelled', 'template'],
    filter_all_status_codes: ['submitted', 'progress', 'review'],
    empty: {
      id: null,
      plan_id: null,
      client_id: null,
      job_id: null,
      description: null,
      note: null,
      salesperson_id: null,
      salesperson_name: null,
      additional_salespeople: [],
      estimator_id: null,
      estimator_name: null,
      billing_selected: ['framing'],
      due_date: null,
      submitted_date: null,
      received_date: null,
      start_date: null,
      finish_date: null,
      invoice_date: null,
      paid_date: null,
      return_cd: 10,
      status_cd: null,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      project_id: '',
      purchase_order: '',
      folder_id: null,
      folder_description: '',
      documents_count: 0,
      price_level: 0,
      fixed_pricing: 0,
      profit: 0,
      check_usage: 0,
      cust_build_id: '',
      heated_sqft: 0,
      unheated_sqft: 0,
      url: null,
      total_rate: 0,
      total_sqft: 0,
      read_only: 0,
      update_program: null,
      update_user_id: null,
      notify: true,
    },
    headers: {
      headerCustomer: {
        text: 'Customer',
        align: 'start',
        value: 'client_id',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerStatus: {
        text: 'Status',
        align: 'start',
        value: 'status_cd',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDocCount: {
        text: '',
        align: 'end',
        value: 'documents_count',
        sortable: false,
        filterable: false,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerReturnTime: {
        text: 'Return Time',
        align: 'start',
        value: 'return_cd',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDueDate: {
        text: 'Due Date',
        align: 'start',
        value: 'due_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerSubmittedDate: {
        text: 'Submitted Date',
        align: 'start',
        value: 'submitted_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerReceivedDate: {
        text: 'Received Date',
        align: 'start',
        value: 'received_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerCompletedDate: {
        text: 'Completed Date',
        align: 'start',
        value: 'finish_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerInvoiceDate: {
        text: 'Invoiced Date',
        align: 'start',
        value: 'invoice_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerPaidDate: {
        text: 'Paid Date',
        align: 'start',
        value: 'paid_date',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerEstimator: {
        text: 'Estimator',
        align: 'start',
        value: 'estimator_name',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerSalesperson: {
        text: 'Salesperson',
        align: 'start',
        value: 'salesperson_name',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerFolderId: {
        text: 'Folder ID',
        align: ' d-none',
        value: 'folder_id',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
      },
      headerFolderDescription: {
        text: 'Folder Description',
        align: ' d-none',
        value: 'folder_description',
        sortable: true,
        filterable: true,
        groupable: true,
        width: '1%',
      },
      headerSqFt: {
        text: 'Framed SqFt',
        align: 'end',
        value: 'heated_sqft',
        sortable: false,
        filterable: false,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerAddress: {
        text: 'Address',
        align: 'start',
        value: 'address',
        sortable: false,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerRate: {
        text: 'Rate',
        align: 'end',
        value: 'total_rate',
        sortable: true,
        filterable: false,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerPrice: {
        text: 'Price',
        align: 'end',
        value: 'fixed_pricing',
        sortable: true,
        filterable: false,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerPurchaseOrder: {
        text: 'PO #',
        align: 'start',
        value: 'purchase_order',
        sortable: false,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerNote: {
        text: 'Note',
        align: 'start',
        value: 'note',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
    },
    treeBranchesSelected: [],
    assemblyTreeData: [
      {
        id: 0,
        text: 'All Selected Plans',
        checked: false,
        children: [],
        state: {
          open_icon: 'mdi-folder-open',
          close_icon: 'mdi-folder',
        },
      },
    ],
    tabbedColumns: {
      id_header: 'Plan ID',
      description_header: 'Plan Description',
    },
    GuestStatusCode: {
      submitted: 'SUBMITTED',
      progress: 'IN PROGRESS',
      review: 'READY TO REVIEW',
      complete: 'COMPLETE',
      invoiced: 'INVOICED',
      hold: 'ON-HOLD',
      cancelled: 'CANCELLED',
    },
    CustomerStatusCode: {
      submitted: 'SUBMITTED',
      progress: 'IN PROGRESS',
      review: 'READY TO REVIEW',
      complete: 'COMPLETE',
      invoiced: 'INVOICED',
      hold: 'ON-HOLD',
      cancelled: 'CANCELLED',
      template: 'TEMPLATE',
    },
    DashboardStatusCode: {
      submitted: 'SUBMITTED',
      progress: 'IN PROGRESS',
      review: 'READY TO REVIEW',
      complete: 'COMPLETE',
      invoiced: 'INVOICED',
      hold: 'ON-HOLD',
      cancelled: 'CANCELLED',
      training: 'TRAINING',
    },
    StatusCode: {
      submitted: 'SUBMITTED',
      progress: 'IN PROGRESS',
      review: 'READY TO REVIEW',
      complete: 'COMPLETE',
      invoiced: 'INVOICED',
      hold: 'ON-HOLD',
      cancelled: 'CANCELLED',
      template: 'TEMPLATE',
      training: 'TRAINING',
    },
    ReturnCode: {
      10: 'Residential Standard',
      5: 'Residential Rush',
      14: 'Commercial/Multi-Family',
    },
  },
}

/***
 * Check the filtered Estimators against the Plan's Client & Estimator Id
 * @param currentPlan   Plan Object containing client_id & estimator_id
 * @param estimatorIds  Selected Estimator's Ids
 * @returns {boolean}   Whether or not the Plan's estimator_id is part of the filtered estimatorIds
 */
function includeEstimator (currentPlan, estimatorIds) {
  let includeIt = false
  let estimatorId = null
  const clientsEstimator = estimatorIds.filter(data =>
    data.client_id.toString() === currentPlan.client_id.toString() &&
    data.estimator_id.toString() === currentPlan.estimator_id.toString(),
  )

  if (clientsEstimator) {
    if (clientsEstimator.length > 0) {
      estimatorId = clientsEstimator[0].estimator_id
    }
  }

  // If both values are not null, let's compare them
  if (estimatorId && currentPlan.estimator_id) {
    // If this estimator is assigned to this plan, let's add it
    if (estimatorId.toString().trim() === currentPlan.estimator_id.toString().trim()) {
      includeIt = true
    }
  }

  return includeIt
}

/***
 * Check if the Plan's Client Id is in the filtered Clients list
 * @param currentPlan Plan Object containing client_id
 * @param clientIds   Selected Client Ids
 * @returns {boolean} Whether or not the Plan's client_id is part of the filtered clientIds
 */
function includeClient (currentPlan, clientIds) {
  let includeIt = false

  if (currentPlan && clientIds) {
    if ({}.hasOwnProperty.call(currentPlan, 'client_id') && clientIds.length > 0) {
      if (currentPlan.client_id) {
        includeIt = clientIds.includes(currentPlan.client_id.toString().trim())
      }
    }
  }

  return includeIt
}

/***
 * Check the filtered Status Codes for the Plan's Status Code
 * @param currentPlan Plan Object containing status_cd
 * @param statusCodes Selected Status Codes
 * @returns {boolean} Whether or not the Plan's status_cd is part of the filtered statusCodes
 */
function includesStatusCode (currentPlan, statusCodes) {
  let includesStatus = false

  if (currentPlan && statusCodes) {
    if ({}.hasOwnProperty.call(currentPlan, 'status_cd')) {
      // Check status filtering
      if (currentPlan.status_cd) {
        if (statusCodes.includes(currentPlan.status_cd)) {
          includesStatus = true
        }
      } else {
        // If null status_cd still push it
        includesStatus = true
      }
    }
  }

  return includesStatus
}

/***
 * Check date filters for Submitted Dates
 * @param payload     Object containing submitted_dates
 * @param currentPlan Plan Object containing submitted_date
 * @returns {boolean} Whether or not submitted_date falls within the submitted_dates range
 */
function isSubmittedDateFilterInRange (payload, currentPlan) {
  let inRange = false

  if ({}.hasOwnProperty.call(currentPlan, 'submitted_date') && {}.hasOwnProperty.call(payload, 'submitted_dates')) {
    if (dateInRange(currentPlan.submitted_date, payload.submitted_dates)) {
      inRange = true
    }
  }

  return inRange
}

/***
 * Check date filters for Due Dates
 * @param payload     Object containing due_dates
 * @param currentPlan Plan Object containing due_date
 * @returns {boolean} Whether or not due_date falls within the due_dates range
 */
function isDueDateFilterInRange (payload, currentPlan) {
  let inRange = false

  if ({}.hasOwnProperty.call(currentPlan, 'due_date') && {}.hasOwnProperty.call(payload, 'due_dates')) {
    if (dateInRange(currentPlan.due_date, payload.due_dates)) {
      inRange = true
    }
  }

  return inRange
}

/***
 * Check date filters for Completed Dates
 * @param payload     Object containing completed_dates
 * @param currentPlan Plan Object containing finish_date
 * @returns {boolean} Whether or not finish_date falls within the completed_dates range
 */
function isCompletedDateFilterInRange (payload, currentPlan) {
  let inRange = false

  if ({}.hasOwnProperty.call(currentPlan, 'finish_date') && {}.hasOwnProperty.call(payload, 'completed_dates')) {
    if (dateInRange(currentPlan.finish_date, payload.completed_dates)) {
      inRange = true
    }
  }

  return inRange
}

/***
 * Check date filters for Invoiced Dates
 * @param payload     Object containing invoiced_dates
 * @param currentPlan Plan Object containing invoice_date
 * @returns {boolean} Whether or not invoice_date falls within the invoiced_dates range
 */
function isInvoicedDateFilterInRange (payload, currentPlan) {
  let inRange = false

  if ({}.hasOwnProperty.call(currentPlan, 'invoice_date') && {}.hasOwnProperty.call(payload, 'invoiced_dates')) {
    if (dateInRange(currentPlan.invoice_date, payload.invoiced_dates)) {
      inRange = true
    }
  }

  return inRange
}

/***
 * Include the Plan for an Estimator Summary Report or not based on the parameters
 * @param estimatorIds      Array of Estimator Ids
 * @param currentPlan       Current Plan Object
 * @param payload           { plans: Array, client_ids: Array, estimator_ids: Array, status_codes: Array, completed_dates: Array, invoiced_dates: Array }
 * @returns {false|boolean} Include the Plan based on the filters or not
 */
function includePlanForEstimatorSummary (estimatorIds, currentPlan, payload) {
  let includePlan = false
  const includesEstimator = includeEstimator(currentPlan, estimatorIds)
  const includesClient = includeClient(currentPlan, payload.client_ids)
  const includesStatus = includesStatusCode(currentPlan, payload.status_codes)
  const inRangeOfCompleted = isCompletedDateFilterInRange(payload, currentPlan)
  const inRangeOfInvoiced = isInvoicedDateFilterInRange(payload, currentPlan)

  // TODO: Do we have to include a date?  Maybe we only include a date if a range was selected by the user?
  includePlan = includesEstimator && includesClient && includesStatus && (inRangeOfCompleted || inRangeOfInvoiced)

  return includePlan
}

/***
 * Include the Plan for a Customer Summary Report or not based on the parameters
 * @param currentCustomer Currently Selected Customer Object
 * @param currentPlan     Current Plan Object
 * @param payload         { plans: Array, status_codes: Array, completed_dates: Array, invoiced_dates: Array }
 * @returns {boolean}     Include the Plan based on the filters or not
 */
function includePlanForCustomerSummary (currentCustomer, currentPlan, payload) {
  let includePlan = false
  const includesClient = includeClient(currentPlan, payload.client_ids)
  const includesStatus = includesStatusCode(currentPlan, payload.status_codes)
  const inRangeOfSubmitted = isSubmittedDateFilterInRange(payload, currentPlan)
  const inRangeOfDue = isDueDateFilterInRange(payload, currentPlan)
  const inRangeOfCompleted = isCompletedDateFilterInRange(payload, currentPlan)
  const inRangeOfInvoiced = isInvoicedDateFilterInRange(payload, currentPlan)

  includePlan = includesClient && includesStatus && (inRangeOfSubmitted || inRangeOfDue || inRangeOfCompleted || inRangeOfInvoiced)

  return includePlan
}

/***
 * List all documents for the deleted Plan and remove any S3 documents for it as well
 * @param docPath S3 path to documents for the deleted Plan
 * @returns {Promise<void>}
 */
async function deletePlanDocs (docPath) {
  await Storage.list(docPath)
    .then(async (result) => {
      for (const currentFile of result) {
        await Storage.remove(currentFile.key)
          .then(result => {
            // console.log('Document Removed', result)
          })
          .catch(err => {
            this.$store.dispatch('error/setError', { name: 'Removing Document', details: err })
          })
      }
    })
    .catch(err => {
      this.$store.dispatch('error/setError', { name: 'Listing Documents to Remove', details: err })
    })
}

const mutations = {
  ...make.mutations(state),

  setIsBusyFrom: (state, isBusy) => {
    state.plans.isBusyFrom = isBusy
  },

  setIsBusy: (state, isBusy) => {
    state.plans.isBusy = isBusy
  },

  setEmptyFrom: (state) => {
    state.plans.selectedFrom = { id: '', description: '' }
    state.plans.dataFrom = []
  },

  // TODO: All modules should do this rather than setting selected in the action
  setSelected: (state, payload) => {
    state.plans.selected = []
    state.plans.selectedItem = { ...payload }
  },

  setSelectedFrom: (state, payload) => {
    state.plans.selectedFrom = { ...payload }
    state.plans.isBusy = false
  },

  setSelectedCustomerPlan: (state, payload) => {},

  setIsFirst: (state) => {
    let isFirst = false
    const selectedObject = state.plans.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.plans.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[0], 'id')) {
          const firstId = thisData[0].id

          if (firstId === selectedId) {
            isFirst = true
          }
        }
      } else {
        isFirst = true
      }
    } else {
      isFirst = true
    }

    state.plans.isFirst = isFirst
  },

  setIsLast: (state) => {
    let isLast = false
    const selectedObject = state.plans.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.plans.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[thisData.length - 1], 'id')) {
          const lastId = thisData[thisData.length - 1].id

          if (lastId === selectedId) {
            isLast = true
          }
        }
      } else {
        isLast = true
      }
    } else {
      isLast = true
    }

    state.plans.isLast = isLast
  },

  // payload: { buildEnv, dataFromDB }
  push2All: (state, payload) => {
    for (const currentData of payload.dataFromDB) {
      state.plans.all.push({ ...currentData })
    }
  },

  // payload: { buildEnv, users, dataFromDB }
  push2Data: (state, payload) => {
    state.plans.data = []
    state.plans.originalData = []

    for (const currentData of payload.dataFromDB) {
      state.plans.data.push({ ...currentData })
      state.plans.originalData.push({ ...currentData })
    }

    state.plans.selected = []

    state.plans.isBusy = false
  },

  push2From: (state, dataFromDB) => {
    state.plans.dataFrom = []
    state.plans.dataFrom = [...dataFromDB]

    state.plans.isBusyFrom = false
  },

  push2Import: (state, data2Import) => {
    state.plans.toImport = []
    state.plans.toImport = data2Import
    state.plans.isBusy = false
  },

  push2EstimatorsSummary: (state, payload) => {
    state.plans.estimatorsSummary = []
    state.plans.estimatorsSummary = [...payload.dataFromDB]

    state.plans.isBusy = false
  },

  push2CustomersSummary: (state, payload) => {
    state.plans.customersSummary = []
    state.plans.customersSummary = [...payload.dataFromDB]

    state.plans.isBusy = false
  },

  initializeAssemblyTreeData: (state, dataFromDB) => {
    state.plans.assemblyTreeData[0].children = []

    let holdPlanId = ''
    let planTree = {}
    let holdDivisionId = ''
    let divisionTree = {}
    let assemblyTree = {}

    const defaultState = {
      open_icon: 'mdi-folder-open',
      close_icon: 'mdi-folder',
    }

    for (const thisItem of dataFromDB) {
      if (thisItem.plan_id && holdPlanId !== thisItem.plan_id) {
        holdPlanId = thisItem.plan_id
        planTree = {
          id: holdPlanId,
          plan_id: holdPlanId,
          text: thisItem.plan_description,
          checked: true,
          state: { ...defaultState },
          children: [],
        }
        planTree.state.open_icon = 'mdi-home-minus'
        planTree.state.close_icon = 'mdi-home-plus'

        state.plans.assemblyTreeData[0].children.push(planTree)
      }

      if (thisItem.phase_id && holdDivisionId !== thisItem.phase_id) {
        holdDivisionId = thisItem.phase_id
        divisionTree = {
          id: `${holdPlanId}_${holdDivisionId}`,
          plan_id: holdPlanId,
          phase_id: holdDivisionId,
          text: thisItem.phase_description,
          checked: true,
          state: { ...defaultState },
          children: [],
        }
        // mdi-layers-plus
        divisionTree.state.open_icon = 'mdi-tray-minus'
        divisionTree.state.close_icon = 'mdi-tray-plus'

        planTree.children.push(divisionTree)
      }

      if (thisItem.assembly_id) {
        assemblyTree = {
          id: `${holdPlanId}_${holdDivisionId}_${thisItem.assembly_id}`,
          plan_id: holdPlanId,
          phase_id: holdDivisionId,
          assembly_id: thisItem.assembly_id,
          text: thisItem.assembly_description,
          checked: true,
          state: { ...defaultState },
        }
        assemblyTree.state.open_icon = 'mdi-toy-brick'
        assemblyTree.state.close_icon = 'mdi-toy-brick'

        divisionTree.children.push({ ...assemblyTree })
      }
    }

    state.plans.isBusy = false
  },

  successfulUpdate: (state) => {},

  importCompleted: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmptyFrom: ({ commit }) => {
    commit('setEmptyFrom')
  },

  setEmpty: ({ commit, dispatch }) => {
    state.plans.selected = []
    state.plans.selectedItem = { id: '', description: '' }
    state.plans.data = []
    dispatch('division/setEmpty', null, { root: true })
    commit('setIsFirst')
    commit('setIsLast')
  },

  setDefaultFrom: ({ commit, rootState }) => {
    let selectedFrom = null
    commit(setIsBusy, true)

    // Select the first template for the currently selected From Customer
    for (const currentItem of state.plans.dataFrom) {
      if ({}.hasOwnProperty.call(currentItem, 'status_cd')) {
        if (currentItem.status_cd) {
          if (currentItem.status_cd.toString() === 'template') {
            selectedFrom = { ...currentItem }
            break
          }
        }
      }
    }

    // If the Customer does not have a template defined, set the first plan as selectedFrom
    if (state.plans.dataFrom.length > 0 && selectedFrom === null) {
      const selectedParent = rootState.customer.customers.selectedFrom
      const selectedItem = state.plans.selectedFrom

      // If we have a selected parent object and selectedItem is empty, select the first data item
      if (selectedParent && {}.hasOwnProperty.call(selectedParent, 'id') && selectedItem && {}.hasOwnProperty.call(selectedItem, 'id')) {
        if (selectedParent.id.trim().length > 0 && selectedItem.id.trim().length <= 0) {
          selectedFrom = { ...state.plans.dataFrom[0] }
        }
      }
    }

    commit('setSelectedFrom', selectedFrom)
  },

  setFrom: ({ commit, dispatch, rootState }, payload) => {
    let selectedFrom = {}
    commit(setIsBusy, true)

    if (payload) {
      selectedFrom = { ...payload }
    } else {
      selectedFrom = { id: '', description: '' }
    }

    commit('setSelectedFrom', selectedFrom)
  },

  setSelected: ({ commit, dispatch }, payload) => {
    dispatch('division/setEmpty', null, { root: true })

    if (payload) {
      commit('setSelected', payload)
      dispatch('division/retrieve', payload, { root: true })
    } else {
      state.plans.selectedItem = { id: '', description: '' }
    }
    commit('setIsFirst')
    commit('setIsLast')
  },

  /**
   * Set plans.data with the provided Customer's Plans
   * @param commit    commit to mutations
   * @param rootState Root State object containing all modules' state
   * @param clientId  Customer Id to pull from plans.all and place in plans.data
   */
  setData: ({ commit, rootState }, clientId) => {
    let customerData = []

    for (const currentItem of state.plans.all) {
      if ({}.hasOwnProperty.call(currentItem, 'client_id')) {
        if (currentItem.client_id && clientId) {
          if (currentItem.client_id.toString() === clientId.toString()) {
            customerData.push(currentItem)
          }
        }
      }
    }

    if (customerData.length <= 0) {
      customerData = state.plans.data
    }

    commit('push2Data', { buildEnv: rootState.app.build_environment, dataFromDB: customerData })
  },

  setSelectedCustomerPlan: async ({ commit, dispatch, rootGetters, rootState }, payload) => {
    const thisAction = `Set Selected ${rootState.customer.displayAsSingular} ${state.displayAsSingular}`
    const selectedCustomer = rootState.customer.customers.selectedItem
    const selectedPlan = state.plans.selectedItem

    if (selectedCustomer !== null) {
      if ({}.hasOwnProperty.call(selectedCustomer, 'client_id')) {
        if (selectedCustomer.client_id.toString().trim() !== payload.client_id.toString().trim()) {
          const setCustomer = await rootGetters['app/getDataById'](rootState.customer.customers.data, payload.client_id)

          if (setCustomer) {
            if (setCustomer.length > 0) {
              await dispatch('customer/setSelected', setCustomer[0], { root: true })
            }
          }
        }
      }
    }

    if (selectedPlan !== null) {
      if ({}.hasOwnProperty.call(selectedPlan, 'plan_id')) {
        const selectedPlanId = selectedPlan.plan_id.toString().trim()

        if (selectedPlanId.length > 0 && selectedPlanId !== payload.plan_id.toString().trim()) {
          const setPlan = await rootGetters['app/getDataById'](state.plans.all, payload.plan_id)

          if (setPlan) {
            if (setPlan.length > 0) {
              await dispatch('setSelected', setPlan[0])
            }
          }
        }
      }
    }

    commit('setSelectedCustomerPlan', payload)
  },

  previous: ({ dispatch, rootState }) => {
    let previousObject = null
    const selectedId = state.plans.selectedItem.id

    for (const existingObject of state.plans.data) {
      if (existingObject.id === selectedId) {
        break
      }

      previousObject = { ...existingObject }
    }

    if (previousObject) {
      rootState.assembly.assemblies.setLast = true
      rootState.division.divisions.setLast = true
      dispatch('setSelected', previousObject)
      // dispatch setSelected last division last assembly
    } else {
      dispatch('customer/previous', null, { root: true })
    }
  },

  next: ({ dispatch }) => {
    let nextObject = null
    const selectedId = state.plans.selectedItem.id

    for (let thisIndex = state.plans.data.length - 1; thisIndex >= 0; thisIndex--) {
      const existingObject = state.plans.data[thisIndex]

      if (existingObject.id === selectedId) {
        break
      }

      nextObject = { ...existingObject }
    }

    if (nextObject) {
      dispatch('setSelected', nextObject)
    }
  },

  determineSelected: ({ dispatch, rootState }, dataFromDB) => {
    const thisAction = `Determine Selected ${state.displayAsSingular}`

    // Previous/Next logic
    if (dataFromDB.length > 0) {
      if (state.plans.setLast) {
        state.plans.setLast = false
        dispatch('setSelected', dataFromDB[dataFromDB.length - 1])
      } else {
        const selectedParent = rootState.customer.customers.selectedItem
        const selectedItem = state.plans.selectedItem

        // If we have a selected parent object and selectedItem is empty, select the first data item
        if (selectedParent && {}.hasOwnProperty.call(selectedParent, 'id') && selectedItem && {}.hasOwnProperty.call(selectedItem, 'id')) {
          if (selectedParent.id.trim().length > 0 && selectedItem.id.trim().length <= 0) {
            dispatch('setSelected', dataFromDB[0])
          }
        }
      }
    }
  },

  retrieveAll: ({ commit, dispatch, getters, rootState }) => {
    const thisAction = 'retrieveAllPlans'
    commit(setIsBusy, true)

    const chunks2Get = []
    const buildEnv = rootState.app.build_environment
    const actionEndPoint = `${endpoint}/${buildEnv}/${thisAction}`
    let url

    for (const currentData of rootState.customer.customers.data) {
      url = `${actionEndPoint}/${currentData.client_id}`
      chunks2Get.push(getChunk(state.displayAsPlural, url))
    }

    if (chunks2Get.length > 0) {
      // Once all promises are resolved we have successfully gotten all client plans, or display rejected error
      Promise.all(chunks2Get)
        .then(responses => {
          state.plans.all = []

          for (const thisResponse of responses) {
            const data2Push = []

            if (_.isEmpty(thisResponse.error)) {
              for (const currentData of thisResponse.data) {
                currentData.additional_salespeople = JSON.parse(currentData.additional_salespeople)
                currentData.notify = (rootState.user.users.isCustomer && !rootState.user.users.isAdmin)
                currentData.billing_selected = JSON.parse(currentData.billing_selected)
                currentData.total_rate = getters.getTotalRate(currentData)
                currentData.fixed_pricing = getters.getTotalPlanPrice(currentData.heated_sqft, currentData.total_rate)

                data2Push.push({ ...currentData })
              }

              commit('push2All', { buildEnv: buildEnv, dataFromDB: data2Push })
            } else {
              console.error(`${thisAction} failed with url: ${url}`)
              commit(setIsBusy, false)
              dispatch('error/setError', thisResponse, { root: true })
              break
            }
          }

          commit(setIsBusy, false)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieve: ({ commit, dispatch, getters, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrievePlan'
      commit(setIsBusy, true)

      const buildEnv = rootState.app.build_environment
      const actionEndPoint = `${endpoint}/${buildEnv}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}/null`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          const data2Push = []

          if (_.isEmpty(jsonResponse.error)) {
            for (const currentData of jsonResponse.data) {
              currentData.additional_salespeople = JSON.parse(currentData.additional_salespeople)
              currentData.notify = (rootState.user.users.isCustomer && !rootState.user.users.isAdmin)
              currentData.billing_selected = JSON.parse(currentData.billing_selected)
              currentData.total_rate = getters.getTotalRate(currentData)
              currentData.fixed_pricing = getters.getTotalPlanPrice(currentData.heated_sqft, currentData.total_rate)

              data2Push.push({ ...currentData })
            }

            commit('push2Data', { buildEnv: buildEnv, dataFromDB: data2Push })
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }

          return data2Push
        })
        .then(async dataFromDB => {
          dispatch('assembly/retrieveAssembliesByPlans', dataFromDB, { root: true })
          dispatch('division/retrieveAll', null, { root: true })

          dispatch('determineSelected', dataFromDB)
        })
        .then(() => {
          dispatch('app/getRealtimeUpdates', null, { root: true })
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieveFrom: ({ commit, dispatch, rootState }) => {
    const displayAction = 'Retrieve From Plans'
    const thisAction = 'retrievePlan'
    commit('setIsBusyFrom', true)

    const selectedFromCustomer = rootState.customer.customers.selectedFrom
    let executeRetrieveAPI = false

    if (selectedFromCustomer && state.plans.data) {
      if (state.plans.data.length > 0) {
        if ({}.hasOwnProperty.call(selectedFromCustomer, 'client_id')) {
          const firstToPlan = state.plans.data[0]

          if (selectedFromCustomer.client_id && firstToPlan.client_id) {
            if (selectedFromCustomer.client_id.toString() !== firstToPlan.client_id.toString()) {
              executeRetrieveAPI = true
            }
          }
        }
      } else {
        executeRetrieveAPI = true
      }
    }

    if (executeRetrieveAPI) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedFrom.client_id}/null`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2From', jsonResponse.data)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${displayAction} failed with url: ${url}`)
            dispatch('error/setError', { name: displayAction, details: jsonResponse.error }, { root: true })
            commit('setIsBusyFrom', false)
          }
        })
        .then(() => {
          dispatch('setDefaultFrom')
        })
        .catch(error => {
          console.error(`${displayAction} failed with url: ${url}`)
          commit('setIsBusyFrom', false)
          dispatch('error/setError', { name: displayAction, details: error }, { root: true })
        })
    } else {
      commit('push2From', state.plans.data)
      dispatch('setDefaultFrom')
    }
  },

  retrieveAssemblyTree: ({ commit, dispatch, rootState }) => {
    const thisAction = 'retrievePlansAssemblyTree'
    commit(setIsBusy, true)

    const clientId = rootState.customer.customers.selectedItem.client_id
    let errMsg = ''

    if (clientId.length <= 0) {
      errMsg += 'Failed with an empty client id'
    }

    if (state.plans.selected.length <= 0) {
      if (errMsg.length > 0) {
        errMsg += ' ,'
      }
      errMsg += 'Failed due to no plans selected'
    }

    if (errMsg.length > 0) {
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: errMsg }, { root: true })
    } else {
      let selectedPlans = ''

      for (const currentData of state.plans.selected) {
        if (selectedPlans.length > 0) {
          selectedPlans += ','
        }
        selectedPlans += currentData.plan_id
      }

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${clientId}/${selectedPlans}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('initializeAssemblyTreeData', jsonResponse.data)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          commit(setIsBusy, false)
        })
    }
  },

  retrieveEstimatorsSummary: ({ commit, dispatch, rootGetters, rootState }, payload) => {
    const thisAction = 'Retrieve Estimator\'s Summary'
    commit(setIsBusy, true)

    try {
      const planData = []
      const estimatorIds = (rootState.estimator.estimators.ids ? [...rootState.estimator.estimators.ids] : [])

      // Include Active Plans
      for (const currentItem of payload.plans) {
        if (includePlanForEstimatorSummary(estimatorIds, currentItem, payload)) {
          planData.push(currentItem)
        }
      }

      // Include Archived Plans
      for (const currentItem of rootState.archive.archives.all) {
        if (includePlanForEstimatorSummary(estimatorIds, currentItem, payload)) {
          planData.push(currentItem)
        }
      }

      payload.dataFromDB = planData
      commit('push2EstimatorsSummary', payload)
    } catch (error) {
      console.error(`${thisAction} failed`)
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: error }, { root: true })
    }
  },

  /***
   * Retrieve into plans.customersSummary the customer summary's filtered plans
   * @param commit      commit to mutations
   * @param dispatch    dispatch other actions
   * @param rootGetters getters from other modules
   * @param rootState   state from other modules
   * @param payload     { plans: Array, status_codes: Array, completed_dates: Array }
   */
  retrieveCustomersSummary: ({ commit, dispatch, rootGetters, rootState }, payload) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'Retrieve Customer\'s Summary'
      commit(setIsBusy, true)

      try {
        const planData = []
        const selectedCustomer = rootState.customer.customers.selectedItem

        // Include Active Plans
        for (const currentItem of payload.plans) {
          if (includePlanForCustomerSummary(selectedCustomer, currentItem, payload)) {
            planData.push(currentItem)
          }
        }

        // Include Archived Plans
        for (const currentItem of rootState.archive.archives.all) {
          if (includePlanForCustomerSummary(selectedCustomer, currentItem, payload)) {
            planData.push(currentItem)
          }
        }

        payload.dataFromDB = planData
        commit('push2CustomersSummary', payload)
      } catch (error) {
        console.error(`${thisAction} failed`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      }
    }
  },

  update: ({ commit, dispatch, rootGetters, rootState }, payload) => {
    const thisAction = 'updatePlans'
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    // Copy payload to workPayload so that we can adjust formatting for MySQL
    const workPayload = { ...payload }

    if (workPayload.plan_id === null) {
      if (workPayload.id != null) {
        workPayload.plan_id = workPayload.id
      }
    } else {
      if (workPayload.plan_id !== workPayload.id) {
        workPayload.plan_id = workPayload.id
      }
    }

    // Prepare JSON values to send to the DB
    workPayload.additional_salespeople = JSON.stringify(workPayload.additional_salespeople)
    workPayload.billing_selected = JSON.stringify(workPayload.billing_selected)

    // Ensure dates are formatted properly
    if (workPayload.submitted_date !== null) {
      workPayload.submitted_date = new Date(workPayload.submitted_date)
    }

    if (workPayload.due_date !== null) {
      workPayload.due_date = new Date(workPayload.due_date)
    }

    if (workPayload.received_date !== null) {
      workPayload.received_date = new Date(workPayload.received_date)
    }

    if (workPayload.finish_date !== null) {
      workPayload.finish_date = new Date(workPayload.finish_date)
    }

    if (workPayload.invoice_date !== null) {
      workPayload.invoice_date = new Date(workPayload.invoice_date)
    }

    const options = {
      method: 'post',
      body: JSON.stringify(workPayload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(async jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.plans.selectedItem && {}.hasOwnProperty.call(state.plans.selectedItem, 'id')) {
            if (workPayload.plan_id === state.plans.selectedItem.id) {
              dispatch('setSelected', workPayload)
            }

            if (workPayload.plan_id === state.plans.selectedFrom.id) {
              dispatch('setFrom', workPayload)
            }
          }

          // On successful update, check original data's status_cd to determine notification, if any
          const originalData = await rootGetters['app/getDataById'](rootState.plan.plans.originalData, workPayload.plan_id)

          switch (workPayload.status_cd) {
            case 'submitted':
              if (originalData.length <= 0) {
                // Plan didn't exist before now, so create submit message, if created by a customer with notify
                if ({}.hasOwnProperty.call(workPayload, 'notify')) {
                  if (rootState.user.users.isCustomer && workPayload.notify) {
                    dispatch('notification/createSubmitMessage', workPayload, { root: true })
                  }
                }
              }
              break
            case 'complete':
              if (originalData.length > 0) {
                if (workPayload.status_cd !== originalData[0].status_cd) {
                  // Status wasn't Complete, but now it is
                  dispatch('notification/createCompletionMessage', workPayload, { root: true })
                }
              }

              break
            default:
              break
          }

          dispatch('retrieve')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${workPayload.description}' ${toastMessage}, variant: ${toastColor}`)
        dispatch('retrieveFrom')
        commit('successfulUpdate')
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${workPayload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  updateAction: ({ commit, dispatch, rootGetters, rootState }, payload) => {
    const thisAction = 'updatePlans'
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/ALL?Action=${payload.action}`

    // Reduce request size to only pass the key data we need to update the statuses
    let selectedItem = {}
    const emptySelectedItem = { client_id: '', plan_id: '' }
    const workSelected = []

    for (const currentSelected of payload.selected) {
      selectedItem = { ...emptySelectedItem }

      selectedItem.client_id = currentSelected.client_id
      selectedItem.plan_id = currentSelected.plan_id

      workSelected.push(selectedItem)
    }

    payload.body.selected = workSelected

    const options = {
      method: 'post',
      body: JSON.stringify(payload.body),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(async jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          commit(setIsBusy, false)
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        commit('successfulUpdate')
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${workPayload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /***
   * Mass update on the status of selected plans
   * @param dispatch  dispatch other actions
   * @param payload   { status_cd: plans.StatusCode, selected: Array, update_program: String, update_user_id: String }
   */
  updateStatus: ({ dispatch }, payload) => {
    payload.action = 'StatusCodes'
    payload.body = {
      status_cd: payload.status_cd,
      selected: [],
      update_program: payload.update_program,
      update_user_id: payload.update_user_id,
    }

    dispatch('updateAction', payload)
  },

  /***
   * Mass update on the paid_date of selected plans
   * @param dispatch  dispatch other actions
   * @param payload   { paid_date: DateTime, selected: Array, update_program: String, update_user_id: String }
   */
  updatePaidDate: ({ dispatch }, payload) => {
    payload.action = 'PaidDate'
    payload.body = {
      paid_date: payload.paid_date,
      selected: [],
      update_program: payload.update_program,
      update_user_id: payload.update_user_id,
    }

    dispatch('updateAction', payload)
  },

  /***
   * Delete the selected plans from plans.selected
   * @param commit      commit to mutations
   * @param dispatch    dispatch other actions
   * @param rootGetters getters from other modules
   * @param rootState   state from other modules
   */
  delete: ({ commit, dispatch, rootGetters, rootState }) => {
    if (state.plans.selected.length > 0) {
      const thisAction = 'deletePlans'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      // TODO: reduce size of workSelected.  All attributes are not needed for delete array.
      const workSelected = [...state.plans.selected]

      const options = {
        method: 'post',
        body: JSON.stringify(workSelected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (workSelected.length > 1) {
        toastPrefix = `${workSelected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${workSelected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.plans.selectedItem && {}.hasOwnProperty.call(state.plans.selectedItem, 'id')) {
              for (const currentData of workSelected) {
                if (currentData.plan_id === state.plans.selectedItem.id) {
                  dispatch('setSelected', state.plans.data[0])
                }

                if (currentData.plan_id === state.plans.selectedFrom.id) {
                  dispatch('setFrom', state.plans.dataFrom[0])
                }
              }
            }

            dispatch('retrieve')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
          */
          // Remove S3 docs of deleted plan(s)
          let docPath = ''

          for (const currentData of workSelected) {
            docPath = rootGetters['app/getDocumentsPath'](currentData)
            deletePlanDocs(docPath)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  importAll: ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'updatePlans'

    if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const emptyItem = { ...state.plans.empty }
      dispatch('user/setStateAsUpdated', emptyItem, { root: true })

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(payload.length / 50)
      const maxSize = Math.ceil(payload.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Import = []
      let totalAttempted = 0
      let items2Import = []
      let item2Import = {}

      for (const currentItem of payload) {
        // Ensure we have all required data and the proper selected client
        item2Import = { ...emptyItem }

        item2Import.id = currentItem.id
        item2Import.plan_id = currentItem.id
        item2Import.description = currentItem.description
        item2Import.status_cd = currentItem.status_cd

        // Prepare JSON values to send to the DB
        item2Import.additional_salespeople = JSON.stringify([])
        item2Import.billing_selected = JSON.stringify([])

        items2Import.push(item2Import)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))

          totalAttempted += chunkSize
          chunkSize = 0
          items2Import = []
        }
      }

      if (chunkSize > 0) {
        chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))
        totalAttempted += chunkSize
      }

      let toastColor = 'success'
      let toastMessage = 'imported successfully'

      if (chunks2Import.length > 0) {
        // Once all promises are resolved we have a successful import, or display rejected error
        Promise.all(chunks2Import)
          .then(responses => {
            dispatch('importData/setImportCount', { sheetName: state.displayAsPlural, importCount: totalAttempted }, { root: true })
            dispatch('importData/setSelectedIsComplete', state.displayAsPlural, { root: true })
            commit('importCompleted')
            dispatch('retrieve')

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)
              } else {
                toastColor = 'danger'
                toastMessage = 'were not imported successfully'
                console.error(`${thisAction} failed with url: ${url}`)
                commit(setIsBusy, false)
                dispatch('importData/setIsBusy', false, { root: true })
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }
          })
          .catch(error => {
            console.error(`${thisAction} failed for ${state.displayAsSingular}`)
            commit(setIsBusy, false)
            dispatch('importData/setIsBusy', false, { root: true })
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        console.error(`${thisAction} failed for ${state.displayAsSingular} due to empty chunks`)
        commit(setIsBusy, false)
        dispatch('importData/setIsBusy', false, { root: true })

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `Import ${state.displayAsPlural}`,
            messages: [`No ${state.displayAsPlural.toLowerCase()} were imported`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } // client_id & payload length > 0
  },

  push2Import: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Push ${state.displayAsPlural} to Import`

    if (payload && {}.hasOwnProperty.call(payload, 'sheetData')) {
      if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.sheetData.length > 0) {
        commit(setIsBusy, true)

        const emptyItem = { id: null, plan_id: null, description: null, note: '' }
        const items2Import = []
        let item2Import = {}

        for (const currentItem of payload.sheetData) {
          item2Import = { ...emptyItem }

          item2Import.id = currentItem[state.plans.tabbedColumns.id_header]
          item2Import.plan_id = currentItem[state.plans.tabbedColumns.id_header]
          item2Import.description = currentItem[state.plans.tabbedColumns.description_header]
          item2Import.status_cd = 'template'

          items2Import.push(item2Import)
        }

        dispatch('importData/setRows', { sheetName: state.displayAsPlural, sheetRows: items2Import.length }, { root: true })
        commit('push2Import', items2Import)
      }
    }
  },

  executeImport: ({ dispatch }) => {
    dispatch('importAll', state.plans.toImport)
  },
}

const getters = {
  filteredPlans: (state) => (payload) => {
    const planData = []

    for (const currentItem of payload.plans) {
      if (currentItem.status_cd) {
        if (payload.status_codes.includes(currentItem.status_cd) &&
          payload.client_ids.includes(currentItem.client_id)) {
          planData.push(currentItem)
        }
      } else {
        // If null status_cd still push it
        planData.push(currentItem)
      }
    }

    return planData
  },

  getPlanAttachmentCount: (state) => (payload) => {
    let attachmentCount = 0

    if (payload) {
      if ({}.hasOwnProperty.call(payload, 'documents_count')) {
        if (payload.documents_count) {
          attachmentCount = payload.documents_count
        }
      }

      if ({}.hasOwnProperty.call(payload, 'url')) {
        if (payload.url) {
          if (payload.url.trim().length > 0) {
            attachmentCount++
          }
        }
      }
    }

    return attachmentCount
  },

  /***
   * Get the Total Rate based on billing rates that were selected
   * @param state -> payload: JSON object containing plan data
   * @returns {function(payload: JSON): Float32List}
   */
  getTotalRate: (state) => (payload) => {
    let rate = 0

    if (payload) {
      if ({}.hasOwnProperty.call(payload, 'billing_selected')) {
        const billingSelected = payload.billing_selected

        if (billingSelected) {
          let selectedCategory
          const billingData = store.state.billing.billing.data

          for (const selectedItem of billingSelected) {
            // Get the rate defined in the billing table
            selectedCategory = billingData.filter(item => item.value === selectedItem)

            if (selectedCategory) {
              if (selectedCategory.length > 0) {
                rate += parseFloat(selectedCategory[0].rate)
              }
            }
          }
        }
      }
    }

    return rate.toFixed(3)
  },

  /***
   * Get the Total Plan Price based on square footage and total rate
   * @param state -> * sqft is the Square Footage of the Plan, * totalRate is the calculated rate based on selected billing options
   * @returns {function(sqft: Float32List, totalRate: Float32List): Float32List}
   */
  getTotalPlanPrice: (state) => (sqft, totalRate) => {
    let total = 0

    if (sqft && totalRate) {
      total = parseFloat(sqft) * parseFloat(totalRate)
    }

    return total.toFixed(2)
  },

  /**
   * Get the plans for the specified client_id
   * @param state -> payload: JSON object containing plan data
   * @returns {function(*): *[]}
   */
  getCustomerPlans: (state) => (clientId) => {
    return state.plans.all.filter(data => data.client_id === clientId)
  },

  getDueDateClass: (state) => (returnCd) => {
    let dueDateClass = ''

    switch (returnCd) {
      case 5:
        dueDateClass = 'red--text text-darken-2 font-weight-bold'
        break
      case 14:
        dueDateClass = 'blue--text font-weight-bold'
        break
      default:
        dueDateClass = 'font-weight-medium'
        break
    }
    return dueDateClass
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
