// Pathify
import { make } from 'vuex-pathify'
import _ from 'lodash'

const endpoint = 'https://okludvbv6g.execute-api.us-east-2.amazonaws.com'
const apiMapping = '/api/v1/tenantrates'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Billing Category',
  displayAsPlural: 'Billing Categories',
  table_name: 'Billing',
  billing: {
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', value: '', description: '' },
    empty: {
      id: '',
      value: '',
      description: '',
      rate: 0,
      icon: '',
      sort_order: 0,
      updated: false,
    },
    backendObject: {
      description: '',
      rate: 0,
      icon: '',
      sortOrder: 0,
      value: '',
    },
    headers: {
      headerSortOrder: {
        text: 'Sort',
        align: 'end',
        value: 'sort_order',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerValue: {
        text: 'Value',
        align: 'start',
        value: 'value',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDescription: {
        text: 'Category',
        align: 'start',
        value: 'description',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerRate: {
        text: 'Rate',
        align: 'end',
        value: 'rate',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerIcon: {
        text: 'Icon',
        align: 'start',
        value: 'icon',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  /**
   * Mutate the busy switch to indicate if the state is busy
   * @param state   this module's state object
   * @param isBusy  data is in CRUD transit
   */
  setIsBusy: (state, isBusy) => {
    state.billing.isBusy = isBusy
  },

  /**
   * Mutate the data and selected state arrays to empty
   * @param state this state object
   */
  setEmpty: (state) => {
    state.billing.selected = []
    state.billing.data = []
  },

  /**
   * Mutate the selectedItem to be the payload object
   * @param state   this module's state object
   * @param payload selected object
   */
  setSelected: (state, payload) => {
    state.billing.selected = []
    state.billing.selectedItem = { ...payload }
  },

  /**
   * Mutate the data state array with the retrieved data
   * @param state       this module's state object
   * @param dataFromDB  data retrieved from the database
   */
  push2Data: (state, dataFromDB) => {
    state.billing.data = []

    let newItem

    for (const currentData of dataFromDB) {
      newItem = { ...state.billing.empty }

      newItem.id = currentData.id
      newItem.value = currentData.value
      newItem.description = currentData.description
      newItem.sort_order = currentData.sortOrder
      newItem.icon = currentData.icon
      newItem.rate = currentData.rate

      state.billing.data.push(newItem)
      state.billing.originalData.push(newItem)
    }

    state.billing.selected = []

    state.billing.isBusy = false
  },

  /**
   * Data has been successfully created
   * @param state this module's state object
   */
  successfulCreate: (state) => {},

  /**
   * Data has been successfully updated
   * @param state this module's state object
   */
  successfulUpdate: (state) => {},

  /**
   * Data has been successfully deleted
   * @param state this module's state object
   */
  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {

  },

  /**
   * Set the data state and selected state to empty array
   * @param commit  commit to mutations
   */
  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  /**
   * Set the selectedItem to the payload object
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param payload   currently selected object
   */
  setSelected: ({ commit, dispatch }, payload) => {
    let selectedItem

    if (payload) {
      selectedItem = { ...payload }
    } else {
      selectedItem = { ...state.billing.empty }
    }

    commit('setSelected', selectedItem)
  },

  /**
   * Create Tenant Rates
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to create
   * @returns {Promise<void>}
   */
  create: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}`

    const workPayload = { ...state.billing.backendObject }

    workPayload.description = payload.description
    workPayload.rate = payload.rate
    workPayload.icon = payload.icon
    workPayload.sortOrder = payload.sort_order
    workPayload.value = payload.value

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieve')
      })
      .then(() => {
        // console.info(`${state.displayAsSingular} Created - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulCreate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Retrieve Tenant's Rates
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  retrieve: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Retrieve ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}?page=0&size=999`

    const options = {
      method: 'get',
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          if (response.status === 404) {
            state.billing.data = []
          } else {
            throw Error(statusMessage)
          }
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (!_.isEmpty(jsonResponse.data)) {
          commit('push2Data', jsonResponse.data)
        } else {
          commit(setIsBusy, false)
        }
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Update a Tenant's Rates
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @param payload   currently selected object to update
   * @returns {Promise<void>}
   */
  update: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/${payload.id}`

    const workPayload = { ...state.billing.backendObject }

    workPayload.description = payload.description
    workPayload.rate = payload.rate
    workPayload.icon = payload.icon
    workPayload.sortOrder = parseInt(payload.sort_order * 1, 0)
    workPayload.value = payload.value

    const options = {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        // TODO: What will I get back for an error?
        if (!_.isEmpty(jsonResponse.data)) {
          dispatch('retrieve')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.message }, { root: true })
        }
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulUpdate')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  /**
   * Delete the selected Tenant Rates
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param rootState state from other modules
   * @returns {Promise<void>}
   */
  delete: async ({ commit, dispatch, rootState }) => {
    const thisAction = `Delete ${state.displayAsPlural}`
    commit(setIsBusy, true)

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}${apiMapping}`
    const url = `${actionEndPoint}/remove`

    const workPayload = []

    for (const thisItem of state.billing.selected) {
      workPayload.push(thisItem.id)
    }

    const options = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(workPayload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          if (response.status === 404) {
            state.billing.data = []
          } else {
            throw Error(statusMessage)
          }
        }

        return response.json()
      })
      .then(jsonResponse => {
        dispatch('retrieve')
      })
      .then(() => {
        // console.info(`${state.displayAsPlural} Deleted - '${workPayload.length}' ${toastMessage}, variant: ${toastColor}`, workPayload)
        commit('successfulDelete')
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
